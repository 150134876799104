import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import CircleIcon from 'src/components/CircleIcon';
import Tooltip from 'src/components/Tooltip';
import { useLocation } from 'react-router-dom';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { ReactComponent as AddIcon } from 'src/assets/icons/plus.svg';
import useModal from 'src/hooks/useModal';
import MerchantModalInner from 'src/pages/businessOffice/Introduce/components/MerchantModalInner';
import { AddingMerchantContext } from 'src/components/ProductsAccordion/context';
import { useIntl } from 'react-intl';
import {
    ArrowWrapper,
    ButtonAddMerchant,
    ClickableItem,
    CollapseBody,
    CollapseBodyItem,
    CollapseBodyItemWrapper,
    CollapseItemWrapper,
    CollapseTitle,
    Label,
    ModalMerchantCreate,
    Title,
} from '../styles';
import { ReactComponent as ErrorIcon } from '../assets/error-icon.svg';
import { ReactComponent as ArrowIcon } from '../assets/arrow-down.svg';

export interface ICollapseBodyItem {
    title: string;
    label?: string;
    id: string;
    activeId?: string;
}

export interface ICollapseItemProps {
    title: string;
    icon: ReactNode;
    items: Array<ICollapseBodyItem>;
    errorTooltipContent?: ReactNode;
    renderItem?: (props: ICollapseBodyItem) => ReactNode;
    activeId?: string;
    isActive?: boolean;
}

const COLLAPSE_BODY_ITEM_HEIGHT = 76;

const CollapseItem: FC<ICollapseItemProps> = ({
    isActive,
    activeId,
    icon,
    title,
    items,
    errorTooltipContent,
    renderItem,
}) => {
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const isCurrentShopPath = location.pathname.includes(RoutesBusinessOffice.shop);
    const collapseBodyHeight = isOpen ? (items.length + 1) * COLLAPSE_BODY_ITEM_HEIGHT : 0;

    const toggle = () => setIsOpen((v) => !v);

    useEffect(() => {
        if (!isCurrentShopPath) {
            setIsOpen(false);
        }
    }, [isCurrentShopPath]);

    const addingMerchantContextProviderValue = useMemo(
        () => ({
            closeModal: onCloseModal,
        }),
        [onCloseModal],
    );

    return (
        <CollapseItemWrapper $isOpen={isOpen}>
            <ClickableItem onClick={errorTooltipContent ? () => {} : toggle} $isActive={isActive}>
                <CircleIcon icon={icon} isActive={isActive} />
                <Title variant="subheading">{title}</Title>
                {errorTooltipContent ? (
                    <Tooltip content={errorTooltipContent} interactive placement="right">
                        <ErrorIcon />
                    </Tooltip>
                ) : (
                    <ArrowWrapper $isOpen={isOpen}>
                        <ArrowIcon />
                    </ArrowWrapper>
                )}
            </ClickableItem>

            <CollapseBody $itemHeight={collapseBodyHeight}>
                {items.map((item) => (
                    <CollapseBodyItemWrapper $itemHeight={COLLAPSE_BODY_ITEM_HEIGHT} key={item.id}>
                        {renderItem ? (
                            renderItem({ title: item.title, label: item.label, id: item.id, activeId })
                        ) : (
                            <CollapseBodyItem>
                                <CollapseTitle variant="regular14">{item.title}</CollapseTitle>
                                {item.label && <Label variant="regular14">{item.label}</Label>}
                            </CollapseBodyItem>
                        )}
                    </CollapseBodyItemWrapper>
                ))}
                <CollapseBodyItemWrapper $itemHeight={COLLAPSE_BODY_ITEM_HEIGHT} key="buttonAddMerchant">
                    <ButtonAddMerchant type="button" onClick={openModal}>
                        <AddIcon />
                        {intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                    </ButtonAddMerchant>
                </CollapseBodyItemWrapper>
            </CollapseBody>
            <ModalMerchantCreate
                title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                isOpen={isOpenModal}
                closeModal={onCloseModal}
            >
                <AddingMerchantContext.Provider value={addingMerchantContextProviderValue}>
                    <MerchantModalInner />
                </AddingMerchantContext.Provider>
            </ModalMerchantCreate>
        </CollapseItemWrapper>
    );
};

export default CollapseItem;
