import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ContentWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    gap: 30px;
    justify-content: space-between;
`;
