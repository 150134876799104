export type TRequiredFields =
    | {
          [key: string]: {
              type: string;
              minLength?: number;
              maxLength?: number;
          };
      }
    | [];

export type TTaskFlow = {
    taskflowCode: TASKFLOW_CODES;
    places: string[];
    requiredFields: TRequiredFields;
};

export enum TASKFLOW_CODES {
    download = 'download',
    edm_non_formalized = 'edm_non_formalized',
    email = 'email',
    mail = 'mail',
}

export type TTaskFlowCode = keyof typeof TASKFLOW_CODES;

export const INVOICE_IDS_FIELD_NAME = 'invoiceIds';
export const TASKFLOW_CODE_FIELD_NAME = 'taskflowCode';
