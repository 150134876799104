import React, { useEffect, useState } from 'react';
import { ReactComponent as IconNotification } from 'src/assets/icons/notification.svg';
import { ReactComponent as IconNewNotification } from 'src/assets/icons/notificationNew.svg';
import { useAppDispatch } from 'src/store';
import { fetchOneFeed, subscribeNotifications } from 'src/store/Feed';
import { getNoun } from 'src/utils/helpers';
import { IFeedItem } from 'src/store/Feed/types';
import { useHistory } from 'react-router-dom';
import { RoutesBusinessOffice } from 'src/pages/constants';
import NotificationWindow from 'src/components/Header/components/NotificationWindow';
import { useIntl } from 'react-intl';
import { toast } from '@invoicebox/ui';
import { IconWrapper, NotificationWrapper } from './styles';
import { isSupportedFirebase, onMessageListener } from '../../../../firebaseInit';

const NotificationMenuItem = () => {
    const intl = useIntl();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const [newFeedsNotification, setNewFeedsNotification] = useState<IFeedItem[]>([]);

    const [feedsIconNotification, setFeedsIconNotification] = useState<IFeedItem[]>([]);
    const [isShowWindowNotifications, setIsShowWindowNotifications] = useState(false);

    useEffect(() => {
        if (isSupportedFirebase) {
            dispatch(subscribeNotifications());
        }
    }, [dispatch]);

    // отображение фидов в открытом приложении каждые 30 секунд
    useEffect(() => {
        if (isSupportedFirebase) {
            const queueFeedsInterval = setInterval(() => {
                if (newFeedsNotification.length > 1) {
                    setFeedsIconNotification(newFeedsNotification);
                    toast.success(
                        `${intl.formatMessage({ defaultMessage: 'У вас' })} ${newFeedsNotification.length} ${getNoun(
                            newFeedsNotification.length,
                            intl.formatMessage({ defaultMessage: 'новое уведомление' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомления' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомлений' }),
                        )}`,
                    );
                }
                if (newFeedsNotification.length === 1) {
                    toast.success(newFeedsNotification[0]?.description);
                    setFeedsIconNotification(newFeedsNotification);
                }
            }, 30000);

            return () => {
                clearInterval(queueFeedsInterval);
            };
        }
    }, [dispatch, intl, newFeedsNotification]);

    // слушатель на пуши при открытом приложении
    if (isSupportedFirebase) {
        onMessageListener().then((payload) => {
            dispatch(fetchOneFeed(payload.data.feedItemId)).then((data) =>
                // @ts-ignore
                setNewFeedsNotification([...newFeedsNotification, data?.payload[0] as IFeedItem]),
            );
        });
    }

    const onClickIconNotifications = () => {
        if (feedsIconNotification.length > 0) {
            setIsShowWindowNotifications(true);
            setFeedsIconNotification([]);
        } else {
            history.push(RoutesBusinessOffice.feeds);
        }
    };

    return (
        <NotificationWrapper>
            <IconWrapper type="button" onClick={onClickIconNotifications}>
                {feedsIconNotification.length === 0 ? <IconNotification /> : <IconNewNotification />}
            </IconWrapper>
            <NotificationWindow
                isShowWindowNotifications={isShowWindowNotifications}
                setIsShowWindowNotifications={setIsShowWindowNotifications}
                feeds={feedsIconNotification}
            />
        </NotificationWrapper>
    );
};

export default NotificationMenuItem;
