import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
`;

export const ArrowBackButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    &:hover {
        opacity: 0.5;
    }
`;

export const ButtonCheckDocumentsStatus = styled.button`
    padding: 5px 8px;
    border-radius: 90px;
    background-color: ${({ theme }) => theme.colors.white()};
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 3px;
    color: #6c6d6e;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
`;

export const CheckStatusLabel = styled(Typography)`
    font-size: 13px;
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
