import { assert } from '../../utils/assert';
import { TASKFLOW_CODES, TRequiredFields, TTaskFlow } from './types';

export class TaskFlow {
    readonly taskflowCode: TASKFLOW_CODES;
    private readonly places: Array<string>;
    readonly requiredFields: TRequiredFields;

    private constructor(state: TTaskFlow) {
        const { places, taskflowCode, requiredFields } = state;

        this.places = places;
        this.taskflowCode = taskflowCode;
        this.requiredFields = requiredFields;
    }

    static fromResponse(response: unknown): TaskFlow {
        assert(typeof response === 'object' && response !== null);

        assert('places' in response);
        const { places } = response;
        assert(Array.isArray(places));
        assert(!!places.length);
        places.forEach((placeItem) => assert(typeof placeItem === 'string'));

        assert('taskflowCode' in response);
        const { taskflowCode } = response;
        assert(typeof taskflowCode === 'string');
        assert(!!taskflowCode.length);

        const taskFlowCodeTypified = taskflowCode as TASKFLOW_CODES;

        assert('requiredFields' in response);
        const { requiredFields } = response;
        assert(typeof requiredFields === 'object' && requiredFields !== null);

        Object.values(requiredFields).forEach((requiredFieldItem) => {
            assert(typeof requiredFieldItem === 'object' && requiredFieldItem !== null);

            assert('type' in requiredFieldItem);
            const { type } = requiredFieldItem;
            assert(typeof type === 'string');

            let minLength;
            if ('minLength' in requiredFieldItem) {
                minLength = requiredFieldItem.minLength;
                assert(typeof minLength === 'number');
                assert(minLength > 0);
            }
        });
        const requiredFieldsTypified = requiredFields as TRequiredFields;

        const checkedResponse = {
            places,
            taskflowCode: taskFlowCodeTypified,
            requiredFields: requiredFieldsTypified,
        } satisfies TTaskFlow;

        return new TaskFlow(checkedResponse);
    }

    static fromMock(): TaskFlow {
        return new TaskFlow({
            places: [
                'created',
                'send',
                'error',
                'withRecipientSignatureReceived',
                'recipientSignatureRequestRejectedReceived',
            ],
            taskflowCode: TASKFLOW_CODES.edm_non_formalized,
            requiredFields: {
                invoiceFileId: {
                    type: 'string',
                    minLength: 36,
                },
                torg12FileId: {
                    type: 'string',
                    minLength: 36,
                },
                counterpartyCode: {
                    type: 'string',
                },
                toBoxId: {
                    type: 'string',
                },
            },
        });
    }
}
