import yup from './validations';

export type TProperty = {
    type: string;
    description?: string;
    format?: string;
    minLength?: number;
    maxLength?: number;
};

const getValidateByFields = (type: string, name: string) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'email': {
            return yup.string().email();
        }
    }

    switch (type) {
        case 'array': {
            return yup.array().transform((curr, orig) => (orig.length === 0 ? null : curr));
        }
        case 'boolean': {
            return yup.boolean();
        }
        case 'string': {
            return yup.string();
        }
        // для селекта с сущностями где ид число
        case 'integer': {
            return yup
                .number()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .typeError('Укажите корректное значение');
        }
        // для инпутов с числом
        case 'number': {
            return yup
                .number()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .typeError('Введите корректное число');
        }
        default: {
            return yup.string();
        }
    }
};

export const getDynamicFormSchemaValidation = (
    required?: string[],
    properties?: {
        [key: string]: TProperty;
    },
    skipFields?: Array<string>,
) => {
    if (!properties) {
        return null;
    }
    const validateArray = Object.entries(properties).map((field) => {
        const fieldName = field[0];
        if (skipFields?.includes(fieldName)) {
            return { [fieldName]: yup.object().optional() };
        }

        const isRequired = required?.includes(fieldName);
        const validate = getValidateByFields(field[1].type, fieldName);

        return {
            [fieldName]: isRequired ? validate.required('Укажите данные').nullable() : validate.nullable(),
        };
    });

    // @ts-ignore
    return Object.assign(...validateArray);
};
