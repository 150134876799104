import { useEffect } from 'react';
import { TaskFlow } from '../../../../store/TaskFlow/entity';

export const useCatchImpossibleEvents = (
    taskflow: TaskFlow | undefined,
    orderContainerIds: Array<string>,
    goToDashboard: () => void,
) => {
    useEffect(() => {
        if (!taskflow || !orderContainerIds.length) {
            goToDashboard();
        }
    }, [goToDashboard, orderContainerIds.length, taskflow]);
};
