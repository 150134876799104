import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Typography from 'src/components/Typography';

export const ChooseMerchantWrapper = styled.div``;

export const MerchantsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin-top: 40px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: 1fr;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 30px;
        grid-row-gap: 10px;
    }
`;

export const MerchantCardButton = styled.button<{
    $isActive: boolean;
}>`
    padding: 20px 20px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid
        ${({ theme, $isActive }) => ($isActive ? theme.colors.yellow() : theme.colors.base(COLOR_BRIGHTNESS.LIGHT))};
    height: 76px;
    overflow: hidden;
    justify-content: flex-start;
`;

export const MerchantContent = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    ${({ theme }) => theme.mixins.getTypography('subheading')}
    white-space: nowrap;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.ld} {
        justify-content: center;
    }
`;

export const ButtonAddNew = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 100%;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHTEST)};
    padding: 26px 0;
    margin-top: 24px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 10px;
    }
`;

export const ButtonContent = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;

export const MerchantName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
