import http, { ICommonResponse } from '../../utils/http';
import { TTaskFlowCode } from '../TaskFlow/types';
import { TTask } from './types';

export type TCreateTaskParams = {
    taskflowCode: TTaskFlowCode;
    data: { [key: string]: any };
};

export const createTaskRequest = (params: TCreateTaskParams) =>
    http.post<ICommonResponse<Array<TTask>>>('/document/paymentgateway/taskflow/task', params);
