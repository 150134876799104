import React, { FC } from 'react';
import { ReactComponent as DocumentImage } from 'src/assets/icons/payment-doc.svg';
import { IContract } from 'src/store/Contracts/types';
import { useIntl } from 'react-intl';
import { ButtonCardTariff, Commission, CommissionValue, Information, TariffDescription, TariffName } from './styles';

type TProps = {
    contractTemplate: IContract;
    selectedContractTemplateId?: number;
    setSelectedContractTemplate?: (arg: IContract) => void;
};

const TariffCard: FC<TProps> = ({ setSelectedContractTemplate, contractTemplate, selectedContractTemplateId }) => {
    const intl = useIntl();

    const onClickToTariff = () => {
        if (setSelectedContractTemplate) {
            setSelectedContractTemplate(contractTemplate);
        }
    };

    return (
        <ButtonCardTariff
            $isActive={contractTemplate.id === selectedContractTemplateId}
            type="button"
            onClick={onClickToTariff}
        >
            <Information>
                <DocumentImage />
                <TariffName variant="title">{contractTemplate.title}</TariffName>
                <TariffDescription variant="regular14">{contractTemplate.shortDescription}</TariffDescription>
            </Information>
            <Commission variant="subheading">
                {intl.formatMessage({ defaultMessage: 'Комиссия от' })}{' '}
                <CommissionValue>{contractTemplate.minFeePercent}%</CommissionValue>
            </Commission>
        </ButtonCardTariff>
    );
};

export default TariffCard;
