import React, { FC } from 'react';
import { TRequiredFields } from '../../../store/TaskFlow/types';
import { RHFTextInput } from '../../formFields/RHFTextInput';
import { FIELD_LABEL } from './constants';

export type TProps = {
    fields: TRequiredFields;
    ignoreFields?: Array<string>;
};

export const FieldsBySchema: FC<TProps> = ({ fields, ignoreFields }) => {
    const fieldsArray = Object.entries(fields).filter((field) => !ignoreFields?.includes(field[0]));

    return (
        <>
            {fieldsArray.map((field) => {
                const { maxLength, type } = field[1];
                const name = field[0];
                const label = FIELD_LABEL[name] || name;

                switch (type) {
                    case 'string': {
                        return <RHFTextInput key={name} name={name} maxLength={maxLength} label={label} />;
                    }

                    default:
                        return <RHFTextInput key={name} name={name} maxLength={maxLength} label={label} />;
                }
            })}
        </>
    );
};
