import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'src/utils/utilityTypes';
import { get } from 'lodash';
import { clearClients } from 'src/store/Client';
import { clearClientCounterparties } from 'src/store/ClientCounterparty';
import { clearCounterparties } from 'src/store/Counterparty';
import { clearMerchants } from 'src/store/Merchant';
import { clearOrders } from 'src/store/Order';
import { unsubscribeNotifications } from 'src/store/Feed';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { IUserProfile } from './types';
import {
    deleteAuthSession,
    getUserProfileRequest,
    submitUserPhone,
    submitVerificationCode,
    updateUserProfileRequest,
} from './http';

export const fetchUserProfile = createAsyncThunk('auth/profileGet', async (token: string) => {
    const {
        data: { profile },
    } = await getUserProfileRequest(token).then((res) => res.data);
    return profile;
});

export const verificationByPhone = createAsyncThunk(
    'auth/enterPhone',
    async (params: { phone: string; token: string }) => {
        const {
            data: { id, leftAttempt, expiredAt },
        } = await submitUserPhone(params.phone, params.token).then((res) => res.data);

        return { id, leftAttempt, expiredAt };
    },
);

export const verificationByCode = createAsyncThunk(
    'auth/enterCode',
    async (params: { code: string; token: string }, { getState, dispatch }) => {
        const verificationId = get(getState() as IAuthState, ['auth', 'verification', 'verificationId']);
        if (verificationId) {
            await submitVerificationCode(verificationId, params.code);
            await dispatch(fetchUserProfile(params.token));
        }
    },
);

// @ts-ignore
export const logout = createAsyncThunk('auth/logout', async (args, thunkAPI) => {
    const { dispatch } = thunkAPI;
    await dispatch(unsubscribeNotifications());
    await dispatch(clearClients());
    await dispatch(clearClientCounterparties());
    await dispatch(clearCounterparties());
    await dispatch(clearMerchants());
    await dispatch(clearOrders());
    await deleteAuthSession();
    return true;
});
export const updateUser = createAsyncThunkWithErrorHandler<
    IUserProfile,
    Pick<IUserProfile, 'firstName' | 'lastName' | 'id' | 'email'>
>('auth/profileUpdate', async ({ id, firstName, lastName, email }) => {
    const {
        data: { data },
    } = await updateUserProfileRequest({ firstName, lastName, email, id });
    return data;
});

interface IVerification {
    verificationId: Nullable<number>;
    verificationCodeIsValid?: boolean;
    leftAttempts: number;
    expiredAt: Nullable<string>;
}

interface IAuthState {
    user: Nullable<IUserProfile>;
    initialized: boolean;
    loading: boolean;
    verification: Nullable<IVerification>;
}

const initialState = {
    user: null,
    loading: false,
    initialized: false,
    verification: null,
} as IAuthState;

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getUserProfile
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.initialized = true;
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(fetchUserProfile.rejected, (state) => {
            state.loading = false;
        });
        // updateUser
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        // verificationByPhone
        builder.addCase(verificationByPhone.pending, (state) => {
            state.loading = true;
            state.verification = {
                verificationId: null,
                leftAttempts: 0,
                expiredAt: null,
                verificationCodeIsValid: true,
            };
        });
        builder.addCase(verificationByPhone.fulfilled, (state, action) => {
            state.loading = false;
            state.verification = {
                verificationId: action.payload.id,
                leftAttempts: action.payload.leftAttempt,
                expiredAt: action.payload.expiredAt,
            };
        });
        builder.addCase(verificationByPhone.rejected, (state) => {
            state.loading = false;
        });
        // verificationByCode
        builder.addCase(verificationByCode.pending, (state) => {
            state.loading = true;
            state.verification = { ...state.verification, verificationCodeIsValid: true } as IVerification;
        });
        builder.addCase(verificationByCode.fulfilled, (state) => {
            state.loading = false;
            state.verification = { ...state.verification, verificationCodeIsValid: true } as IVerification;
        });
        builder.addCase(verificationByCode.rejected, (state) => {
            state.loading = false;
            const prevVerification = state.verification as IVerification;
            state.verification = {
                ...prevVerification,
                verificationCodeIsValid: false,
                leftAttempts: prevVerification.leftAttempts - 1,
            };
        });
        // logout
        builder.addCase(logout.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.user = null;
            state.loading = false;
        });
        builder.addCase(logout.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default auth.reducer;
