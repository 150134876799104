import styled from 'styled-components';
import Button from '../../../../../components/Button';

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px 0;
`;

export const Buttons = styled.div`
    padding: 10px 0;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: flex-end;
    gap: 12px;
`;

export const FormButton = styled(Button)`
    border-radius: 50px;
    padding: 8px 16px;
`;
