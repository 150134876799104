import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    ButtonCopyCode,
    ButtonEnterCodeLater,
    CodeTimer,
    CodeValue,
    CodeWrapper,
    ModalIntegrationCodeContent,
    ModalIntegrationCodeTitle,
    Subtitle,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Integrations/components/ModalIntegrationCode/styles';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Nullable } from 'src/utils/utilityTypes';
import { toast } from '@invoicebox/ui';

interface IModalIntegrationCode {
    onClose: () => void;
    isOpen: boolean;
    isAfterCreateIntegration: boolean;
    codeActivation: string;
    expiredTime: string;
}

const ModalIntegrationCode: FC<IModalIntegrationCode> = ({
    codeActivation,
    expiredTime,
    isAfterCreateIntegration,
    onClose,
    isOpen,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const nowTimeDate = dayjs();
    const expiredTimeDate = dayjs(expiredTime);
    const countSecondsBetween = expiredTimeDate.diff(nowTimeDate, 'second');

    const [secondsLeft, setSecondsLeft] = useState(countSecondsBetween);
    const minutesLeft = Math.floor(secondsLeft / 60);
    const secondsLeftWithoutMinutes = secondsLeft % 60;
    const secondsDisplay =
        String(secondsLeftWithoutMinutes)?.length === 1 ? `0${secondsLeftWithoutMinutes}` : secondsLeftWithoutMinutes;

    // если происходит открытие ранее открытой модалки - перезаписываем оставшееся время актуальным значением
    useEffect(() => {
        setSecondsLeft(countSecondsBetween);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiredTime]);

    const refTimeoutExpiredTime = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

    const onCopyCode = async () => {
        if (navigator.clipboard?.writeText) {
            await navigator.clipboard?.writeText(codeActivation);
            toast.success(intl.formatMessage({ defaultMessage: 'Скопировано в буфер обмена' }));
        }
    };

    const clearRefAndTimer = () => {
        if (refTimeoutExpiredTime.current) {
            clearTimeout(refTimeoutExpiredTime.current);
            refTimeoutExpiredTime.current = null;
        }
    };

    const onCloseWithReset = useCallback(() => {
        clearRefAndTimer();

        history.replace({ state: {} });
        onClose();
    }, [history, onClose]);

    useEffect(() => {
        clearRefAndTimer();

        if (secondsLeft > 0) {
            refTimeoutExpiredTime.current = setTimeout(() => {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);
        } else {
            onCloseWithReset();
        }
        return () => {
            clearRefAndTimer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondsLeft, expiredTime]);

    return (
        <ModalIntegrationCodeContent closeModal={onCloseWithReset} isOpen={isOpen}>
            <ModalIntegrationCodeTitle variant="title">
                {isAfterCreateIntegration
                    ? intl.formatMessage({ defaultMessage: 'Интеграция успешно добавлена' })
                    : intl.formatMessage({ defaultMessage: 'Сформирован новый код' })}
            </ModalIntegrationCodeTitle>
            <Subtitle variant="regular14">
                {intl.formatMessage({ defaultMessage: 'Чтобы закончить настройку, введите на кассе код активации' })}
            </Subtitle>
            <CodeWrapper>
                <CodeValue variant="medium34">{codeActivation}</CodeValue>
                <ButtonCopyCode onClick={onCopyCode}>
                    <CopyIcon />
                </ButtonCopyCode>
                <CodeTimer variant="regular10">
                    {intl.formatMessage({ defaultMessage: 'Код действителен' })} {`${minutesLeft}:${secondsDisplay}`}
                </CodeTimer>
            </CodeWrapper>
            <ButtonEnterCodeLater type="secondary" onClick={onCloseWithReset}>
                {intl.formatMessage({ defaultMessage: 'Ввести код позже' })}
            </ButtonEnterCodeLater>
        </ModalIntegrationCodeContent>
    );
};

export default ModalIntegrationCode;
