import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { addDocuments } from 'src/store/Document';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { IClaim, IClaimPassportData, IUpdateClaimFields, IUpdateClaimStatusInfo } from './types';
import {
    createClaimRequest,
    fetchClaimsRequest,
    removeClaimRequest,
    updateClaimRequest,
    updateClaimStatusRequest,
} from './http';

export const claimsAdapter = createEntityAdapter<IClaim>();
const initialState = claimsAdapter.getInitialState({
    selectedId: null as string | null,
    passportData: {} as IClaimPassportData,
    isLoaded: false,
});

export const fetchClaims = createAsyncThunkWithErrorHandler<Array<IClaim>>(
    'claims/getClaims',
    async (arg, thunkAPI) => {
        const {
            data: { data, extendedData },
        } = await fetchClaimsRequest({ _include: ['document'], _extend: ['merchant-contract'] });
        const documents = extendedData.find(({ entity }) => entity === 'document')?.data;
        if (documents) {
            thunkAPI.dispatch(addDocuments(documents));
        }
        return data;
    },
);

export const createClaim = createAsyncThunkWithErrorHandler<IClaim, string>('claims/create', async (merchantId) => {
    const {
        data: { data },
    } = await createClaimRequest(merchantId);
    return data;
});

export const updateClaim = createAsyncThunkWithErrorHandler<IClaim, { claimId: string; payload: IUpdateClaimFields }>(
    'claims/update',
    async ({ claimId, payload }) => {
        const {
            data: { data },
        } = await updateClaimRequest(claimId, payload);
        return data;
    },
);

export const updateClaimStatus = createAsyncThunkWithErrorHandler<
    IClaim,
    { id: string; payload: IUpdateClaimStatusInfo }
>('claims/updateStatus', async ({ id, payload }) => {
    const {
        data: { data },
    } = await updateClaimStatusRequest(id, payload);
    return data;
});

export const removeClaim = createAsyncThunkWithErrorHandler('claim/remove', async (id: string) => {
    await removeClaimRequest(id);
    return id;
});

const claims = createSlice({
    name: 'claims',
    initialState,
    reducers: {
        setSelectedClaimId(state, action) {
            state.selectedId = action.payload;
        },
        setPassportData(state, action) {
            state.passportData = action.payload;
        },
    },
    extraReducers: (builder) => {
        // fetchClaims
        builder.addCase(fetchClaims.fulfilled, (state, { payload }) => {
            claimsAdapter.setAll(state, payload);
            state.isLoaded = true;
        });
        // createClaim
        builder.addCase(createClaim.fulfilled, (state, { payload }) => {
            claimsAdapter.addOne(state, payload);
        });
        // updateClaim
        builder.addCase(updateClaim.fulfilled, (state, { payload }) => {
            claimsAdapter.updateOne(state, { id: payload.id, changes: payload });
        });
        builder.addCase(updateClaimStatus.fulfilled, (state, { payload }) => {
            claimsAdapter.updateOne(state, { id: payload.id, changes: payload });
        });
        // removeClaim
        builder.addCase(removeClaim.fulfilled, (state, { payload }) => {
            claimsAdapter.removeOne(state, payload);
        });
    },
});

export const { setSelectedClaimId, setPassportData } = claims.actions;
export default claims.reducer;
