export enum APP_VARIANT_ROUTES {
    dashboard = '/dashboard',
    docs = '/docs',
}

export const RoutesDocs = {
    // main
    auth: `${APP_VARIANT_ROUTES.docs}/auth`,
    dashboard: `${APP_VARIANT_ROUTES.docs}/dashboard`,
    statuses: `${APP_VARIANT_ROUTES.docs}/statuses`,
    getDocuments: `${APP_VARIANT_ROUTES.docs}/getDocuments`,
};

export const RoutesBusinessOffice = {
    // routes first level
    profile: `${APP_VARIANT_ROUTES.dashboard}/profile`,
    dashboard: `${APP_VARIANT_ROUTES.dashboard}/dashboard`,
    auth: `${APP_VARIANT_ROUTES.dashboard}/auth`,
    introduce: `${APP_VARIANT_ROUTES.dashboard}/introduce`,
    marketplace: `${APP_VARIANT_ROUTES.dashboard}/marketplace`,
    payment: `${APP_VARIANT_ROUTES.dashboard}/payment`,
    shop: `${APP_VARIANT_ROUTES.dashboard}/shop`,
    reference: `${APP_VARIANT_ROUTES.dashboard}/reference`,
    news: `${APP_VARIANT_ROUTES.dashboard}/news`,
    ordercontainer: `${APP_VARIANT_ROUTES.dashboard}/ordercontainer`,
    refundCreate: `${APP_VARIANT_ROUTES.dashboard}/refundCreate`,
    counterparty: `${APP_VARIANT_ROUTES.dashboard}/counterparty`,
    feeds: `${APP_VARIANT_ROUTES.dashboard}/feeds`,
    claim: `${APP_VARIANT_ROUTES.dashboard}/claim`,

    // routes for profile
    personal: `${APP_VARIANT_ROUTES.dashboard}/profile/personal`,
    organizations: `${APP_VARIANT_ROUTES.dashboard}/profile/organizations`,

    // routes for shop
    overview: '/overview',
    orders: '/orders',
    refunds: '/refunds',
    clients: '/clients',
    orderToPay: '/ordertopay',
    createclient: '/createclient',
    createcounterparty: '/createcounterparty',
    settings: '/settings',
    detailclient: '/detailclient',
    detailclientcounterparty: '/detailclientcounterparty',
    refunddetail: '/refunddetail',
    integrations: '/integrations',
    createintegration: '/createintegration',
};
