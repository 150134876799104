import React from 'react';
import {
    ButtonsWrapper,
    DocumentsWrapper,
} from 'src/components/Widgets/ClaimModeratingWidget/components/Documents/styles';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';
import { IButtonDocument } from 'src/pages/businessOffice/Claim/components/Documents';
import DocumentCard from 'src/pages/businessOffice/Claim/components/DocumentCard';
import { useSelector } from 'react-redux';
import { getUploadDocumentByContractId } from 'src/store/Document/selectors';
import { useAppDispatch } from 'src/store';
import { IDocument, IDocumentPayload } from 'src/store/Document/types';
import { createDocument } from 'src/store/Document';
import { saveAs } from 'file-saver';
import { getCurrentSelectClaim } from 'src/store/Claims/selectors';
import { IClaim } from 'src/store/Claims/types';
import { ClaimFields } from 'src/store/Claims/constants';
import HelperUpload from 'src/pages/businessOffice/Claim/components/HelperUpload';
import { useIntl } from 'react-intl';
import { toast } from '@invoicebox/ui';

const Documents = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const selectedClaim: IClaim = useSelector(getCurrentSelectClaim) as any;
    const uploadDocument: IDocument = useSelector(
        getUploadDocumentByContractId(selectedClaim[ClaimFields.merchantContractId]),
    ) as any;

    const buttonsActionsDocuments: IButtonDocument[] = [
        {
            id: 1,
            title: intl.formatMessage({ defaultMessage: 'Скачать документы' }),
            description: intl.formatMessage({
                defaultMessage:
                    'Здесь можно дать описание того, какие документы будут скачаны, для чего они, что с ними необходимо сделать и какую-либо еще информацию, которая является необходимой для пользователя.',
            }),
            action: async () => {
                const createDocumentConfig: IDocumentPayload = {
                    type: 'merchant-contract-package',
                    format: 'pdf',
                    counterpartyId: selectedClaim.counterpartyId,
                    generate: true,
                    setting: {
                        merchantContractId: selectedClaim.merchantContractId,
                    },
                };

                const data = await dispatch(createDocument(createDocumentConfig));
                const documentData: IDocument = data.payload as any;
                const urlDocument: string = documentData.url;
                saveAs(urlDocument, 'document.pdf');
            },
            isDropZoneUpload: false,
            icon: <DownloadIcon />,
        },
        {
            id: 2,
            title: intl.formatMessage({ defaultMessage: 'Скачать подписанные документы' }),
            description: intl.formatMessage({
                defaultMessage:
                    'Тут , по всей видимости, можно дать пользователю краткую информацию о том, куда отправятся документы и как долго они будет на рассмотрении.',
            }),
            action: () => {
                if (uploadDocument) {
                    saveAs(uploadDocument.url, 'document.pdf');
                }
            },
            isDropZoneUpload: false,
            icon: <DownloadIcon />,
        },
    ];

    return (
        <DocumentsWrapper>
            <ButtonsWrapper>
                {buttonsActionsDocuments.map((button) => (
                    <DocumentCard key={button.id} buttonDocument={button} />
                ))}
            </ButtonsWrapper>
            <HelperUpload
                openToast={() => {
                    toast.success(intl.formatMessage({ defaultMessage: 'Скопировано в буфер обмена' }));
                }}
            />
        </DocumentsWrapper>
    );
};

export default Documents;
