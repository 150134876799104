import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { ReactComponent as ArrowIcon } from './assets/arrow-right.svg';
import { ReactComponent as ArrowBackIcon } from './assets/arrow-back.svg';

export type TProps = {
    onDocumentStatusCheck: () => void;
    onBack?: () => void;
};

export const HeaderContentPage: FC<TProps> = ({ onDocumentStatusCheck, onBack }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.TitleWrapper>
                {onBack && (
                    <S.ArrowBackButton type="button" onClick={onBack}>
                        <ArrowBackIcon />
                    </S.ArrowBackButton>
                )}

                <Typography variant="headline2">
                    {intl.formatMessage({
                        id: 'Запрос отчётных документов',
                        defaultMessage: 'Запрос отчётных документов',
                    })}
                </Typography>
            </S.TitleWrapper>

            <S.ButtonCheckDocumentsStatus type="button" onClick={onDocumentStatusCheck}>
                <S.CheckStatusLabel variant="captionRegular">
                    {intl.formatMessage({
                        id: 'Проверка статуса отправления доументов',
                        defaultMessage: 'Проверка статуса отправления доументов',
                    })}
                </S.CheckStatusLabel>
                <S.ArrowWrapper>
                    <ArrowIcon />
                </S.ArrowWrapper>
            </S.ButtonCheckDocumentsStatus>
        </S.Wrapper>
    );
};
