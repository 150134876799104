import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { Task } from './entity';
import { createTaskRequest, TCreateTaskParams } from './http';

export const taskAdapter = createEntityAdapter<Task>();

const initialState = taskAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const createTask = createAsyncThunk('task/create', async (params: TCreateTaskParams) => {
    const { data } = await createTaskRequest(params);

    return data;
});

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createTask.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createTask.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            taskAdapter.addOne(state as EntityState<Task>, Task.fromResponse(payload.data));
        });
        builder.addCase(createTask.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default taskSlice.reducer;
