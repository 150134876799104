import React, { FC } from 'react';
import logoImage from 'src/assets/icons/logoBigQR.png';
import colors from 'src/theme/colors';
import { copyOrShareLink } from 'src/utils/helpers';
import { useMobile } from 'src/hooks/useMedia';
import { QRCode } from 'react-qrcode-logo';
import Skeleton from 'src/components/Skeleton';
import { useIntl } from 'react-intl';
import { CopyLink, CopyLinkWrapper, QRSubtitle, QRTitle, SkeletonLink, WrapperQRBlock, WrapperQRCode } from './styles';

interface IQRCode {
    paymentURL: string;
}

const QRCodeBlock: FC<IQRCode> = ({ paymentURL }) => {
    const intl = useIntl();

    const isMobile = useMobile();

    return (
        <WrapperQRBlock>
            <QRTitle variant="subheading">{intl.formatMessage({ defaultMessage: 'QR код для оплаты' })}</QRTitle>
            <QRSubtitle variant="regular12">
                {intl.formatMessage({ defaultMessage: 'Сгенерированный системой QR код которым вы можете поделиться' })}
            </QRSubtitle>
            <WrapperQRCode>
                {paymentURL ? (
                    <QRCode
                        value={paymentURL}
                        eyeRadius={6}
                        size={190}
                        quietZone={8}
                        logoImage={logoImage}
                        logoWidth={45}
                        bgColor={colors.lightGrey()}
                    />
                ) : (
                    <Skeleton borderRadius="base" />
                )}
            </WrapperQRCode>
            <CopyLinkWrapper>
                {paymentURL ? (
                    <CopyLink
                        type="button"
                        onClick={() => {
                            copyOrShareLink(paymentURL, isMobile, intl);
                        }}
                    >
                        {intl.formatMessage({ defaultMessage: 'Скопировать ссылку на оплату' })}
                    </CopyLink>
                ) : (
                    <SkeletonLink />
                )}
            </CopyLinkWrapper>
        </WrapperQRBlock>
    );
};

export default QRCodeBlock;
