import yup from '../../../../../utils/validations';
import { TRequiredFields } from '../../../../../store/TaskFlow/types';
import { getDynamicFormSchemaValidation } from '../../../../../utils/getGynamicFormSchemaValidation';

export const getReceiveDocumentSchema = (fields: TRequiredFields) => {
    const required = Object.keys(fields);

    return yup.object().shape({
        ...getDynamicFormSchemaValidation(required, Array.isArray(fields) ? undefined : fields),
    });
};
