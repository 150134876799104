import React, { FC } from 'react';
import AppRoutes from './AppRoutes';
import { useConsoleLogAppVersion } from '../hooks/useConsoleLogAppVersion';

const Pages: FC = () => {
    useConsoleLogAppVersion();

    return <AppRoutes />;
};

export default Pages;
