import React, { FC, ReactNode } from 'react';
import * as S from './styles';
import { LogoWrapper } from './styles';

export type TProps = {
    label: string;
    logo: ReactNode;
    bgColor: string;
    iconColor: string;
    endIcon: ReactNode;
    isSmall?: boolean;
};

export const DocumentReceiveMethod: FC<TProps> = ({ label, bgColor, iconColor, logo, endIcon, isSmall = false }) => (
    <S.Wrapper $bgColor={bgColor}>
        <LogoWrapper $bgColor={iconColor} $isSmall={isSmall}>
            {logo}
        </LogoWrapper>
        <S.Label variant="buttonM">{label}</S.Label>
        <S.EndIconWrapper $color={iconColor}>{endIcon}</S.EndIconWrapper>
    </S.Wrapper>
);
