import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import * as S from './styles';
import { TTaskFlowCode } from '../../../../store/TaskFlow/types';
import { DocumentReceiveMethodWidget } from '../DocumentReceiveMethodWidget';

export type TProps = {
    variant: TTaskFlowCode;
    children: ReactNode;
};

export const FormMethodReceiveDocumentContainer: FC<TProps> = ({ children, variant }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.Header>
                <Typography variant="headerText">
                    {intl.formatMessage({
                        id: 'Способ получения документов:',
                        defaultMessage: 'Способ получения документов:',
                    })}
                </Typography>
                <DocumentReceiveMethodWidget isSmall variant={variant} endIcon={<ArrowDownIcon />} />
            </S.Header>
            {children}
        </S.Wrapper>
    );
};
