import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div<{ $bgColor: string }>`
    padding: 8px 12px 8px 8px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 12px;
    background-color: ${({ $bgColor }) => $bgColor};
    border-radius: 24px;
`;

export const LogoWrapper = styled.div<{ $bgColor: string; $isSmall: boolean }>`
    border-radius: 50px;
    background-color: ${({ $bgColor }) => $bgColor};
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding: ${({ $isSmall }) => ($isSmall ? '2px' : '4px')};

    svg {
        width: ${({ $isSmall }) => ($isSmall ? '16px' : '24px')};
        height: ${({ $isSmall }) => ($isSmall ? '16px' : '24px')};
    }
`;

export const EndIconWrapper = styled.div<{ $color: string }>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    path {
        stroke: ${({ $color }) => $color};
    }
`;

export const Label = styled(Typography)`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
