import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import decorations from 'src/theme/decorations';

type TProps = {
    circle?: boolean;
    width?: number;
    height?: number;
    className?: string;
    borderRadius?: keyof typeof decorations.borderRadius;
};

const Skeleton: FC<TProps> = ({ height, width, borderRadius = 'min', className, circle = false }) => (
    <SkeletonWrapper className={className}>
        <SkeletonInner>
            <SkeletonBody $width={width} $height={height} $borderRadius={borderRadius} $circle={circle} />
        </SkeletonInner>
    </SkeletonWrapper>
);

export default Skeleton;

const SkeletonWrapper = styled.span`
    display: flex;
`;
const SkeletonInner = styled.span`
    width: 100%;
`;

const skeletonAnimation = keyframes`
    from {
      background-position: -200px 0;
    }
    to {
      background-position: calc(200px + 100%) 0;
    }
`;

const SkeletonBody = styled.span<{
    $width?: number;
    $height?: number;
    $circle: boolean;
    $borderRadius: keyof typeof decorations.borderRadius;
}>`
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: ${({ theme, $circle, $borderRadius }) =>
        $circle ? '50%' : `${theme.decorations.borderRadius[$borderRadius]}px`};
    display: inline-block;
    line-height: 1;
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
    animation: ${skeletonAnimation} 1.2s ease-in-out infinite;
`;
