import styled, { css, keyframes } from 'styled-components';
import { delaySwitchSlidesInStoriesInSec } from 'src/utils/constants';

export const ProgressBarWrapper = styled.div`
    position: relative;
    z-index: 3;
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 13px;
    justify-content: space-between;
    width: 100%;
`;

const moveInterval = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const animationMoveInterval = () => css`
    ${moveInterval} ${delaySwitchSlidesInStoriesInSec}s linear 1 forwards;
`;

export const IntervalSlide = styled.div<{
    $isActive: boolean;
    $isFull: boolean;
    $isPaused: boolean;
}>`
    height: 2px;
    background: ${({ theme }) => theme.colors.white()};
    width: 100%;

    &:after {
        content: '';
        background: ${({ theme }) => theme.colors.yellow()};
        display: block;
        width: ${({ $isFull }) => ($isFull ? '100%' : '0')};
        height: 100%;
        animation: ${({ $isActive }) => ($isActive ? animationMoveInterval : 'none')};
        animation-play-state: ${({ $isPaused }) => ($isPaused ? 'paused' : 'running')};
    }
`;
