import React, { FC, useEffect, useState } from 'react';
import { IFilter, IFilterStatus, IOrderContainer, TOrder, typeOrder } from 'src/store/Order/types';
import { COUNT_ITEMS_TO_PAGE, createdFinishField, createdStartField, statusField } from 'src/utils/constants';
import { Container, FilterButton, HeaderLeft, RowForFilter } from 'src/components/FilterOrders/styles';
import { getAllStatusLabel, getOrderContainerStatuses } from 'src/store/Order/constants';
import Calendar from 'src/components/FilterOrders/components/Calendar';
import PaginationStack from 'src/components/FilterOrders/components/Pagination';
import BaseWidget from 'src/components/Widgets/BaseWidget';
import sizeWidget from 'src/theme/widget';
import { useSWRInfinite } from 'src/hooks/useSWR';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { getUserId } from 'src/store/Auth/selectors';
import OrdersMerchantList from 'src/components/MerchantOrdersList/components/OrdersMerchantList';
import { getOrdersContainerList } from 'src/utils/helpers/getOrdersContainerList';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

interface IOrderContainerFilter {
    searchString: string;
}

const OrderContainerFilter: FC<IOrderContainerFilter> = ({ searchString }) => {
    const intl = useIntl();
    const [filter, setFilter] = useState<IFilter>();
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [pagesButtonsCount, setPagesButtonsCount] = useState(0);

    const currentUserId = useSelector(getUserId);

    const payerCounterpartyId = useSelector(getCurrentCounterpartyId);

    const { data } = useSWRInfinite<IOrderContainer[]>('billing/api/order/order-container', {
        ...(isNil(payerCounterpartyId) ? { payerUserId: currentUserId } : { payerCounterpartyId }),
        status: undefined,
        _include: ['order'],
        _extend: ['merchant'],
        _page: currentPage,
        '_order[createdAt]': 'desc',
        ...filter,
        _searchQuery: searchString,
    });

    const ordersList: TOrder[] = (data ? getOrdersContainerList(data) : []) as any;

    const metaData = data && data[0]?.metaData;
    const onToggle = () => {
        setIsOpenCalendar(!isOpenCalendar);
    };
    const handlerAllStatus = () => {
        setCurrentPage(1);
        setFilter({});
    };

    const handlerAcceptDate = (dates: Date[]) => {
        const dateStart = dayjs.utc(dates[0]).format();
        const dateEnd = dayjs.utc(dates[1]).format();
        setFilter({ ...filter, [createdStartField]: String(dateStart), [createdFinishField]: String(dateEnd) });
        setIsOpenCalendar(false);
        setCurrentPage(1);
    };
    const handlerWidgetStatus = (status: IFilterStatus) => {
        setCurrentPage(1);
        setFilter({ [statusField]: status.state });
    };
    const handleChange = (value: number) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        if (metaData?.totalCount && metaData?.totalCount > COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(Math.ceil(metaData.totalCount / COUNT_ITEMS_TO_PAGE));
        } else if (metaData && metaData?.totalCount <= COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(1);
        }
    }, [metaData, metaData?.totalCount]);

    return (
        <BaseWidget size={sizeWidget.xl}>
            <RowForFilter>
                <HeaderLeft>{intl.formatMessage({ defaultMessage: 'Счета' })}</HeaderLeft>
                <Container>
                    <FilterButton $isActive={!(filter && filter[statusField])} onClick={handlerAllStatus}>
                        {getAllStatusLabel(intl)}
                    </FilterButton>
                    {getOrderContainerStatuses(intl).map((status) => (
                        <FilterButton
                            $isActive={(filter && filter[statusField] === status.state) || false}
                            key={status.label}
                            onClick={() => handlerWidgetStatus(status)}
                        >
                            {status.label}
                        </FilterButton>
                    ))}
                </Container>
                <Calendar isOpenCalendar={isOpenCalendar} onToggle={onToggle} handlerAcceptDate={handlerAcceptDate} />
            </RowForFilter>
            <OrdersMerchantList isLoading={!data} orderType={typeOrder.ORDER_CONTAINER} orders={ordersList} />
            <PaginationStack pagesButtonsCount={pagesButtonsCount} pageNumber={currentPage} onChange={handleChange} />
        </BaseWidget>
    );
};

export default OrderContainerFilter;
