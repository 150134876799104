import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { RoutesBusinessOffice } from '../../constants';
import DocsContentWrapper from '../../../components/DocsContentWrapper';
import DocumentReceiveMethods from './components/DocumentReceiveMethods';
import OrderContainerTable from './components/OrderContainerTable';
import DocsHeaderContentPageWidget from '../../../components/DocsHeaderContentPageWidget';

const DashboardDocs = () => {
    const history = useHistory();
    const [selectedOrderContainerIds, setSelectedOrderContainerIds] = useState<Array<string>>([]);

    const handleAddCounterparty = () => {
        history.push(RoutesBusinessOffice.organizations);
    };

    return (
        <div>
            <DocsHeaderContentPageWidget />
            <DocsContentWrapper>
                <OrderContainerTable
                    addCounterparty={handleAddCounterparty}
                    selectedRowIds={selectedOrderContainerIds}
                    onSelectedRowIdsChange={setSelectedOrderContainerIds}
                />
                <S.DocumentReceiveMethodsWrapper>
                    <DocumentReceiveMethods
                        orderContainerIds={selectedOrderContainerIds}
                        isDisabled={!selectedOrderContainerIds.length}
                    />
                </S.DocumentReceiveMethodsWrapper>
            </DocsContentWrapper>
        </div>
    );
};

export default DashboardDocs;
