import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { INVOICE_IDS_FIELD_NAME } from '../../../../../store/TaskFlow/types';
import { FormMethodReceiveDocumentContainer } from '../../../../../stories/docs/widgets/FormMethodReceiveDocumentContainer';
import FormProvider from '../../../../../components/FormProvider';
import { FieldsBySchema } from '../../../../../stories/docs/FieldsBySchema';
import * as S from './styles';
import { TaskFlow } from '../../../../../store/TaskFlow/entity';
import { getReceiveDocumentSchema } from './validation';

type TProps = {
    taskFlow: TaskFlow;
    orderContainerIds: Array<string>;
    onBack: () => void;
};

const FormReceiveDocuments: FC<TProps> = ({ taskFlow, orderContainerIds, onBack }) => {
    const intl = useIntl();

    const defaultValues = {
        [INVOICE_IDS_FIELD_NAME]: [orderContainerIds],
    };

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(getReceiveDocumentSchema(taskFlow.requiredFields)),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid },
    } = methods;

    const submit = () => {};

    return (
        <FormMethodReceiveDocumentContainer variant={taskFlow.taskflowCode}>
            <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
                <S.InputsWrapper>
                    <FieldsBySchema fields={taskFlow.requiredFields} ignoreFields={[INVOICE_IDS_FIELD_NAME]} />
                </S.InputsWrapper>
                <S.Buttons>
                    <S.FormButton htmlType="button" type="secondary" onClick={onBack}>
                        {intl.formatMessage({
                            id: 'e3dEpO',
                            defaultMessage: 'Назад',
                        })}
                    </S.FormButton>
                    <S.FormButton htmlType="submit" disabled={!isValid}>
                        {intl.formatMessage({
                            id: 'Подтвердить и отправить',
                            defaultMessage: 'Подтвердить и отправить',
                        })}
                    </S.FormButton>
                </S.Buttons>
            </FormProvider>
        </FormMethodReceiveDocumentContainer>
    );
};

export default FormReceiveDocuments;
