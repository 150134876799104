import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderContentPage } from '../../stories/docs/HeaderContentPage';
import { RoutesDocs } from '../../pages/constants';

type TProps = {
    onBack?: () => void;
};

const DocsHeaderContentPageWidget: FC<TProps> = ({ onBack }) => {
    const history = useHistory();

    const handleDocumentStatusCheck = () => {
        history.push(RoutesDocs.statuses);
    };

    return <HeaderContentPage onDocumentStatusCheck={handleDocumentStatusCheck} onBack={onBack} />;
};

export default DocsHeaderContentPageWidget;
