import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrow-right.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import * as S from './styles';
import { DocumentReceiveMethodWidget } from '../../../../../stories/docs/widgets/DocumentReceiveMethodWidget';
import { RoutesDocs } from '../../../../constants';
import { getTaskFlowByCode } from '../../../../../store/TaskFlow/selectors';
import {
    INVOICE_IDS_FIELD_NAME,
    TASKFLOW_CODE_FIELD_NAME,
    TASKFLOW_CODES,
    TTaskFlowCode,
} from '../../../../../store/TaskFlow/types';
import { useAppDispatch } from '../../../../../store';
import { createTask } from '../../../../../store/Task';
import path from '../../../../../utils/path';
import { ORDER_CONTAINER_ARRAY_SERIALIZED_SPLITTER, ORDER_CONTAINER_IDS_QUERY_NAME } from '../../../constants';

type TProps = {
    orderContainerIds: Array<string>;
    isDisabled: boolean;
};

const DocumentReceiveMethods: FC<TProps> = ({ orderContainerIds, isDisabled }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const mailTaskFlow = useSelector(getTaskFlowByCode('mail'));
    const emailTaskFlow = useSelector(getTaskFlowByCode('email'));
    const downloadTaskFlow = useSelector(getTaskFlowByCode('download'));
    const edmTaskFlow = useSelector(getTaskFlowByCode('edm_non_formalized'));

    const serializedArray = orderContainerIds.join(ORDER_CONTAINER_ARRAY_SERIALIZED_SPLITTER);
    const queryParams = new URLSearchParams({
        [ORDER_CONTAINER_IDS_QUERY_NAME]: serializedArray,
    });

    const handleGoToGetDocumentPage = (taskflowCode: string) => {
        history.push({
            pathname: path(RoutesDocs.getDocuments, taskflowCode),
            search: queryParams.toString(),
        });
    };

    const handleGetByMail = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.mail);
    };

    const handleGetByEdo = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.edm_non_formalized);
    };

    const handleGetByEmail = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.email);
    };

    const handleGetByDownload = () => {
        dispatch(
            createTask({
                [TASKFLOW_CODE_FIELD_NAME]: TASKFLOW_CODES.download,
                data: {
                    [INVOICE_IDS_FIELD_NAME]: orderContainerIds,
                },
            }),
        );
    };

    const documentReceiveMethodsList: Array<{
        onClick: () => void;
        variant: TTaskFlowCode;
        icon: ReactNode;
        isActive: boolean;
    }> = [
        {
            variant: TASKFLOW_CODES.mail,
            onClick: handleGetByMail,
            icon: <ArrowRightIcon />,
            isActive: !!mailTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.edm_non_formalized,
            onClick: handleGetByEdo,
            icon: <ArrowRightIcon />,
            isActive: !!edmTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.email,
            onClick: handleGetByEmail,
            icon: <ArrowRightIcon />,
            isActive: !!emailTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.download,
            onClick: handleGetByDownload,
            icon: <DownloadIcon />,
            isActive: !!downloadTaskFlow,
        },
    ].filter((i) => i.isActive);

    return (
        <S.Wrapper>
            <Typography variant="headerText">
                {intl.formatMessage({ defaultMessage: 'Способ получения документа' })}
            </Typography>
            <S.DocumentReceiveMethodsListWrapper>
                {documentReceiveMethodsList.map((methodItem) => (
                    <S.ButtonDocumentReceiveMethod
                        key={methodItem.variant}
                        type="button"
                        onClick={methodItem.onClick}
                        disabled={isDisabled}
                    >
                        <DocumentReceiveMethodWidget variant={methodItem.variant} endIcon={methodItem.icon} />
                    </S.ButtonDocumentReceiveMethod>
                ))}
            </S.DocumentReceiveMethodsListWrapper>
        </S.Wrapper>
    );
};

export default DocumentReceiveMethods;
