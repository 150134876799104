export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_SKIP_INTL_ERROR = true;
export const UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const ISO_SHORT_DATE_FORMAT = 'DD.MM.YYYY HH:mm';
export const DD_MM_YYYY_DATE_FORMAT = 'DD.MM.YYYY';
export const HH_MM_FORMAT = 'HH:mm';
export const DATE_YYYY_MM_DD = 'YYYY-MM-DD';
export const INDIVIDUAL_VAT_NUMBER_LENGTH = 12;
export const ORGANISATION_VAT_NUMBER_LENGTH = 10;
export const ORGANISATION_OGRN = 13;
export const INDIVIDUAL_OGRNIP = 15;
export const statusField = 'status[eq]';
export const createdStartField = 'createdAt[ge]';
export const createdFinishField = 'createdAt[le]';
export const COUNT_ITEMS_TO_PAGE = 10;
export const COUNT_ORDERS_MERCHANT_DASHBOARD = 2;
export const PIECE_MEASURE_VALUE = '796'; // id единицы измерения - Штука
export const labelTroublesInternetConnection = 'Проблемы с интернет соединением';
export const labelTroubleXHR = 'Ошибка сети';
export const networkErrorResponse = 'Error: Network Error';
export const delaySwitchSlidesInStoriesInSec = 8;
export const APIDescriptionLink = 'https://docs.invoicebox.ru/docs/notification';
export const maxLengthInputsMerchantIntegration = 50;
export const offerInvoiceBoxLink = 'https://www.invoicebox.ru/ru/offer.html';
export const supportInvoiceBoxLink = 'https://www.invoicebox.ru/ru/support/buyer';
export const privacyInvoiceBoxLink = 'https://www.invoicebox.ru/ru/privacypolicy/russia/main.html';
export const aboutInvoiceBoxLink = 'https://www.invoicebox.ru/ru/about.html';
export const newsInvoiceBoxLink = 'https://www.invoicebox.ru/ru/news';
export const partnersInvoiceBoxLink = 'https://www.invoicebox.ru/ru/partners.html';
export const pressInvoiceBoxLink = 'https://www.invoicebox.ru/ru/press.html';
export const contactsInvoiceBoxLink = 'https://www.invoicebox.ru/ru/contacts.html';
export const corprulesInvoiceBoxLink = 'https://www.invoicebox.ru/ru/corprules.html';
export const amlpolicyInvoiceBoxLink = 'https://www.invoicebox.ru/ru/amlpolicy.html';

export const appStoreAppLink =
    'https://apps.apple.com/ru/app/%D1%81%D1%87%D1%91%D1%82-%D0%B1%D1%83%D1%85%D0%B3%D0%B0%D0%BB%D1%82%D0%B5%D1%80%D1%83/id6450540522';
export const playMarketAppLink = 'https://play.google.com/store/apps/details?id=ru.invoicebox.invoiceForAccountant';
export const ruStoreAppLink = 'https://www.rustore.ru/catalog/app/ru.invoicebox.invoiceForAccountant';
export const nashStoreAppLink = 'https://store.nashstore.ru/store/656ef3740a39b29c049eab78';
export const apkLink = 'https://typewriter.invbox.ru/uploads/invoice_for_accountant_11993192d6.apk?rnd';
export const invoiceForAccountantLink = 'https://счёт-бухгалтеру.рф/';

export const supportEmail = 'c-support@invoicebox.ru';
export const supportPhone1 = '+7 (812) 448-08-01';
export const supportPhone2 = '+7 (499) 638-27-67';
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
