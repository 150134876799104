import React, { FC } from 'react';
import { IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import RowLabel from 'src/components/RowLabel';
import AddressForm from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/AddressForm';
import getValidHoursDay from 'src/utils/helpers/getValidHoursDay';
import putTogetherTimetable from 'src/utils/helpers/putTogetherTimetable';
import DateHoursWork from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/DateHoursWork';
import { useIntl } from 'react-intl';
import { FORM_MERCHANT_ADDRESS } from '../../index';
import { DateHoursContainer, SkeletonValue } from './styles';
import { GeneralInformationSettings, RowSetting, LabelSetting } from '../../styles';

interface IAddress {
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    toggleSaving?: () => void;
    merchant: IMerchant;
}

const emptyHoursDayWork = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
};

const Address: FC<IAddress> = ({
    isEditMode = false,
    formId = FORM_MERCHANT_ADDRESS,
    setIsEditSettings = () => null,
    toggleSaving = () => null,
    merchant,
}) => {
    const intl = useIntl();
    const isShowSkeleton = !merchant?.[MERCHANT_FIELDS.name];

    const hoursWork = merchant?.hours ? getValidHoursDay(merchant?.hours) : emptyHoursDayWork;

    // @ts-ignore
    const groupHours = putTogetherTimetable(hoursWork);

    return (
        <GeneralInformationSettings $isEdit={isEditMode}>
            {isEditMode ? (
                <AddressForm
                    toggleSaving={toggleSaving}
                    formId={formId}
                    setIsEditSettings={setIsEditSettings}
                    merchant={merchant}
                    // @ts-ignore
                    worksHours={hoursWork}
                />
            ) : (
                <>
                    <RowLabel
                        label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                        isShowSkeleton={isShowSkeleton}
                        value={merchant?.[MERCHANT_FIELDS.address]}
                    />
                    <RowSetting>
                        <LabelSetting variant="regular14">
                            {intl.formatMessage({ defaultMessage: 'Режим работы' })}
                        </LabelSetting>
                        {isShowSkeleton ? (
                            <SkeletonValue />
                        ) : (
                            <DateHoursContainer>
                                {groupHours.map((dayWork) => (
                                    <DateHoursWork
                                        startPointDay={dayWork.startPointDay}
                                        endPointDay={dayWork.endPointDay}
                                        time={dayWork.time}
                                        isEdit={false}
                                        key={dayWork.startPointDay}
                                    />
                                ))}
                            </DateHoursContainer>
                        )}
                    </RowSetting>
                </>
            )}
        </GeneralInformationSettings>
    );
};

export default Address;
