import React, { FC } from 'react';
import { InvoiceboxLogo, Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ProfileMenuItem } from '../Header/components/ProfileMenuItem';
import { RoutesBusinessOffice } from '../../pages/constants';
import { useAppDispatch } from '../../store';
import { logout } from '../../store/Auth';

type TProps = {
    userName: string | null;
    menuLinks?: Array<{ href: string; label: string }>;
};

const DocsHeader: FC<TProps> = ({ userName, menuLinks }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const logoutHandler = async () => {
        dispatch(logout());
    };

    return (
        <S.Wrapper>
            <S.Content>
                <S.LogoButton href={RoutesBusinessOffice.dashboard}>
                    <InvoiceboxLogo height={26} />
                </S.LogoButton>
                <S.MenuLinks>
                    {menuLinks?.map((menuItem) => (
                        <S.MenuItem key={menuItem.label} to={menuItem.href}>
                            {menuItem.label}
                        </S.MenuItem>
                    ))}
                </S.MenuLinks>
                {userName ? (
                    <ProfileMenuItem
                        isBorderLeft={false}
                        profileLink={RoutesBusinessOffice.personal}
                        logout={logoutHandler}
                    >
                        <S.LoginButton>
                            <UserIcon />
                            <Typography variant="buttonM">{userName}</Typography>
                        </S.LoginButton>
                    </ProfileMenuItem>
                ) : (
                    <S.LoginButton as="a" href={RoutesBusinessOffice.auth}>
                        <UserIcon />
                        <Typography variant="buttonM">
                            {intl.formatMessage({ id: 'Войти', defaultMessage: 'Войти' })}
                        </Typography>
                    </S.LoginButton>
                )}
            </S.Content>
        </S.Wrapper>
    );
};

export default DocsHeader;
