import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './styles';
import FormReceiveDocuments from './components/FormReceiveDocuments';
import { TASKFLOW_CODE_FIELD_NAME, TASKFLOW_CODES } from '../../../store/TaskFlow/types';
import { getTaskFlowByCode } from '../../../store/TaskFlow/selectors';
import { ORDER_CONTAINER_ARRAY_SERIALIZED_SPLITTER, ORDER_CONTAINER_IDS_QUERY_NAME } from '../constants';
import DocsHeaderContentPageWidget from '../../../components/DocsHeaderContentPageWidget';
import { RoutesDocs } from '../../constants';
import { useCatchImpossibleEvents } from './hooks/useCatchImpossibleEvents';

const GetDocuments = () => {
    const { taskflowCode } = useParams() as { [TASKFLOW_CODE_FIELD_NAME]: TASKFLOW_CODES };
    const taskFlow = useSelector(getTaskFlowByCode(taskflowCode));
    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const serializedArray = queryParams.get(ORDER_CONTAINER_IDS_QUERY_NAME);
    const orderContainerIds = serializedArray ? serializedArray.split(ORDER_CONTAINER_ARRAY_SERIALIZED_SPLITTER) : [];

    const handleGoToDashboard = () => {
        history.push(RoutesDocs.dashboard);
    };

    useCatchImpossibleEvents(taskFlow, orderContainerIds, handleGoToDashboard);

    return (
        <S.Wrapper>
            <DocsHeaderContentPageWidget onBack={handleGoToDashboard} />
            <S.ContentWrapper>
                <div>Documents list</div>
                {taskFlow && (
                    <FormReceiveDocuments
                        onBack={handleGoToDashboard}
                        taskFlow={taskFlow}
                        orderContainerIds={orderContainerIds}
                    />
                )}
            </S.ContentWrapper>
        </S.Wrapper>
    );
};

export default GetDocuments;
